.bg-primary {
  background-color: #0075aa !important;
}

.boxSelected,
.listItem:hover,
.divSelect:hover {
  background-color: rgba(64, 115, 189, 0.25) !important;
}

.text-primary,
.habitat-theme .text-primary {
  color: #0075aa !important;
}

.svgFill {
  fill: #0075aa !important;
}

.svgStroke {
  stroke: #0075aa !important;
}

.buttonBorder {
  border: solid 1.4px #0075aa;
}

.justBorder {
  box-shadow: 2px 2px 8px 0 #b4d7e4;
  border: solid 1px #0075aa;
  background-color: rgba(64, 115, 189, 0.05);
}

.active {
  color: #0075aa !important;
  opacity: 1;
}

.inputBorderColor:focus {
  border-bottom: 1px solid #0075aa !important;
}

.p-dropdown.p-dropdown {
  width: 100%;
  border-radius: 0px;
  border: none;
  border-bottom: solid 1px #9E9E9E;
}

.p-dropdown.p-dropdown:hover {
  background-color: rgba(#0073a8, 0.1);
  border-color: #0073a8;
}

.p-dropdown.p-dropdown.p-focus {
  box-shadow: none;
  border-color: #9E9E9E;
}

.p-dropdown.p-dropdown.p-dropdown-label {
  width: 0px;
  border-radius: 0px;
  text-overflow: ellipsis;
  font-size: 14px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  padding: 0px 0px 0px 2px;
}

/* MATERIAL */

.mat-header-row {
  border-bottom: 1px solid #0075aa;
}

.mat-paginator-container {
  border-top: 1px solid #0075aa;
  padding-top: 8px !important;
}

.mat-radio-button + .mat-radio-button {
  margin-top: 4px;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #0075aa !important;
}

.mat-radio-outer-circle {
  border-color: #0075aa !important;
}

.mat-badge-warn .mat-badge-content {
  background: #0075aa !important;
}

.mat-calendar-body-selected {
  background-color: #0075aa !important;
  color: #fff !important;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0075aa !important;
}

.mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  border-color: #0075aa !important;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: #0075aa !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #0075aa !important;
}

.mat-focused .mat-form-field-label mat-label {
  border-bottom: solid 1px #0075aa !important;
  color: #0075aa !important;
  opacity: 1;
}

/* Other */

.month-cell:hover,
.year-cell:hover,
.day-cell:hover {
  background-color: #0075aa !important;
}

.line {
  height: 1px;
  opacity: 1;
  background-color: #0075aa;
}

.mat-paginator-icon {
  color: #0075aa;
}

.search-Icon {
  cursor: pointer;
  font-size: 25px !important;
  color: #0075aa;
  margin-left: -33px;
  margin-bottom: 4px;
}

.habitat-theme a:hover {
  color: #6baac7 !important;
}

