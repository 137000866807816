html,
body {
  height: 100%;
  background-color: #edf1f7;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

* {
  box-sizing: border-box;
}

.bi::before {
  font-size: 16px;
  vertical-align: middle;
}

mat-label {
  opacity: 0.5;
  color: #525252;
}

mat-card,
mat-expansion-panel.mat-expansion-panel {
  margin-bottom: 1rem;
}

mat-dialog-container mat-card {
  margin: 0;
}

mat-card-header.mat-card-header {
  flex-direction: column;
}

.mat-button {
  color: #525252;
}

.mat-paginator-container {
  padding-top: 8px !important;
}

.mat-paginator-range-actions {
  gap: 8px;
}

mat-error {
  display: flex;
  width: 100%;
  margin-top: 0rem;
  color: #dc3545;
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0.5rem !important;
}

button {
  background-color: #fff;
  border-radius: 10px !important;
  border: solid 0px !important;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

button:focus,
input:focus {
  outline: none !important;
  outline-width: 0 !important;
}

.marginBodyCard {
  line-height: 1.5rem;
  padding: 1.25rem;
  min-width: 300px;
  max-width: 800px;
}

.marginLineHeaderCard {
  line-height: 1.5rem;
  padding: 1.25rem;
  border-bottom: 1px solid #edf1f7;
}

.titleN1 {
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #525252;
}

.titleN2 {
  height: 29px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.2px;
  color: #525252;
}

.titleN3 {
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #525252;
}

.titleN4 .mat-dialog-title {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 900 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px !important;
  color: #525252;
  margin: 0px !important;
}

.mat-dialog-container {
  padding: 0px !important;
}

.light-text {
  color: #d3d3d3 !important;
}

.addButton {
  height: 40px;
  border-radius: 8px;
  text-align: left;
}

.grayBorder {
  background-color: #fff;
  border-radius: 10px !important;
  border: solid 0px !important;
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-content: center;
  line-height: 1.5rem;
  padding: 10px;
  width: 160px;
}

.boxSelected {
  font-weight: 600;
  text-decoration: underline;
}

.justText {
  font-weight: 400;
}

.justBorder {
  height: 56px;
  border-radius: 10px;
}

.justBorderDisabled {
  height: 56px;
  opacity: 0.4;
  border-radius: 10px;
  border: solid 1px #525252;
  background-color: white;
}

.svgStrokeDisabled {
  stroke: #525252 !important;
}

.smallIcon {
  height: 24px;
  width: 24px;
}

.mediumIcon {
  height: 32px;
  width: 32px;
}

.modalTitle {
  margin-bottom: 20px;
}

.modalButton {
  width: 176px;
  height: 40px;
  border-radius: 20px !important;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  border: none;
}

.modalButton:disabled {
  border: solid 1px gray !important;
  color: gray !important;
  background-color: transparent !important;
  box-shadow: none;
}

.modalCancel {
  width: 176px;
  height: 40px;
  border-radius: 20px !important;
  font-size: 16px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  line-height: normal;
  letter-spacing: normal;
  border: none;
  box-shadow: none !important;
}

.buttonsRow {
  margin-top: 16px;
  display: flex;
  justify-content: space-evenly;
}

/* Material */

.cdk-overlay-container {
  z-index: 1003;
}

mat-select {
  padding: 8px;
}

.mat-row {
  border-bottom-width: 0px !important;
}

.mat-header-cell {
  font-size: 14px !important;
  font-weight: 900 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.8px !important;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: 0;
  width: 16px;
  height: 16px;
  line-height: 16px;
  transform: none;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #edf1f7;
}

.mat-badge-content {
  margin-top: -8px;
  margin-left: -6px;
  font-size: 9px;
  color: #fff;
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.mat-progress-bar-buffer {
  background-color: #fffae5;
}

.mat-progress-bar-fill:after {
  background-color: #ffd100;
}

.mat-list-base .mat-list-item,
.mat-list-base .mat-list-option {
  height: 25px !important;
}

.mat-select-panel {
  background: white !important;
  border: 1px solid #d3d3d3 !important;
}

.mat-slide-toggle.mat-accent:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(82, 82, 82, 0.25);
}

.mat-slide-toggle.mat-accent:not(.mat-disabled) .mat-slide-toggle-thumb,
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: white;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.mat-focused .mat-form-field-label mat-label {
  background-color: white !important;
  outline: none !important;
  outline-width: 0 !important;
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  color: grey !important;
  background-color: white !important;
  outline: none !important;
  outline-width: 0 !important;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-datepicker-content {
  background-color: #ffffff !important;
}

.habitat-theme .label {
  opacity: 0.5;
  color: #525252;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
}

.custom-file-label::after {
  display: none !important;
}

.clock-face__number {
  z-index: 1 !important;
}

.mat-button-toggle-checked {
  background-color: #6baac769 !important;
  color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-content {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.loader {
  display: inline-block;
  border: 4px solid #52525225;
  border-top-color: #0075aa;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

/* PrimeNG styles */
.p-button {
  background: #0075aa;
}

.p-slidemenu.p-slidemenu-overlay {
  position: initial;
  top: 62px !important;
}
